(function ($) {
  $('.main-menu .menu-item-has-children > a').on('click', function (e) {
    var li = $(this).parent();

    console.log('here');

    if (!li.hasClass('sub-menu-opened') && $(window).width() < 992) {
      li.addClass('sub-menu-opened');
      e.preventDefault();
    }
  });

  $('.btn--play').click(function () {
    $(this).closest('.tab-pane').removeClass('active');
    $('#video').tab('show');

    var url = $('#video-play').data('src');
    $('#video-play').attr('src', url);
  });
})(jQuery);